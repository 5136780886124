import React, { useState } from "react";
import axios from "axios";

const ChatBot = () => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([{ text: "Hello! How can I assist you today?", sender: "bot" }]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSendMessage = async () => {
    if (input.trim() === "") return;

    const newMessages = [...messages, { text: input, sender: "user" }];
    setMessages(newMessages);
    setInput("");

    // Call OpenAI API
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo", // أو يمكنك استخدام 'gpt-4' إذا كنت تملك حق الوصول
          messages: [{ role: "user", content: input }],
          max_tokens: 150,
        },
        {
          headers: {
            Authorization: `Bearer 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`, // قم باستخدام مفتاح API الخاص بك هنا
          },
        }
      );

      const botReply = response.data.choices[0].message.content.trim();
      setMessages([...newMessages, { text: botReply, sender: "bot" }]);
    } catch (error) {
      console.error("Error calling OpenAI API", error.response ? error.response.data : error.message);
      setMessages([...newMessages, { text: "Sorry, something went wrong. Please try again.", sender: "bot" }]);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <div className="chatbot">
      <div className="chatbot-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            {message.text}
          </div>
        ))}
      </div>
      <div className="chatbot-input">
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Type a message..."
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
};

export default ChatBot;