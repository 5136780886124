const contentData = [
  {
    title: "Insurance Validator",
    link: "#/validator",
    description:
      "Verifies the accuracy of patient insurance data by cross-checking it with the Health Insurance Council’s portal, ensuring valid and up-to-date insurance coverage."
  },
  {
    title: "Claims Checker",
    link: "#/Claims Checker",
    description:
      "Automates the process of validating medical claims before submission, identifying potential errors or discrepancies to reduce rejections and improve approval rates."
  },
  {
    title: "RCM Indicators",
    link: "#/indicators",
    description:
      "Provides KPIs to track performance, offering insights into claim approval rates, and overall revenue generation. Helps identify areas for improvement and optimize financial outcomes."
  },
  {
    title: "Team Work-list",
    link: "#/work-list",
    description:
      "Organizes tasks and workflows within the RCM process, helping teams prioritize and efficiently manage claim submissions, follow-ups, and other RCM activities."
  },

  {
    title: "Notifications Center",
    link: "#/notifications",
    description:
      "Centralized hub for receiving real-time updates and alerts related to claims, insurance validation, workflow progress, and any RCM-related issues, ensuring timely action."
  },
  {
    title: "Workflow Automation",
    link: "#/workflow-automation",
    description:
      "Streamlines the entire revenue cycle by automating key processes, from patient registration to approval and claim submission, enhancing efficiency and minimizing manual effort."
  },
  {
    title: "Knowledge Base",
    link: "#/knowledge-base",
    description:
      "Integrating with the Knowledge Center of the Council of Health Insurance to provide regulations and circulars related to RCM, helping the RCM AI Agent find quick solutions."
  },
  {
    title: "RCM ChatBot",
    link: "#/",
    description:
      "An AI-driven assistant that helps staff with common queries related to RCM processes, offering real-time support for navigating tools, claim statuses, and insurance validations."
  }
];

export default contentData;
