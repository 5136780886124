import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { saveAs } from 'file-saver';
import jsPDF from "jspdf";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Indicators.css'; // Import your custom CSS file
import { FaBan, FaCheck, FaClock, FaWallet } from 'react-icons/fa';


// Import and register Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Indicators = () => {
  const [customDateRange, setCustomDateRange] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("Inpatients");

  // Example data for the chart
  const data = {
    labels: ["Claim Approval Rate", "Revenue Generation", "Pending Claims"],
    datasets: [
      {
        label: "RCM KPIs",
        data: [85, 100000, 120], // Example values
        backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
      },
    ],
  };

  // Handle button clicks for date selection
  const handleDateButtonClick = (type) => {
    const today = new Date();
    switch (type) {
      case 'today':
        setStartDate(today);
        setEndDate(today);
        break;
      case 'yesterday':
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        setStartDate(yesterday);
        setEndDate(yesterday);
        break;
      case 'tomorrow':
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        setStartDate(tomorrow);
        setEndDate(tomorrow);
        break;
      case 'this_week':
        // Logic to set dates for this week
        break;
      case 'last_week':
        // Logic to set dates for last week
        break;
      case 'this_month':
        // Logic to set dates for this month
        break;
      case 'this_year':
        // Logic to set dates for this year
        break;
      case 'custom':
        // You can implement additional logic for custom dates here if needed
        break;
      default:
        break;
    }
  };

  // Export functions (unchanged)
  const exportToExcel = () => {
    const blob = new Blob(["RCM Indicators Data"], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    saveAs(blob, "RCM_Indicators.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("RCM Indicators", 20, 20);
    doc.save("RCM_Indicators.pdf");
  };

  const exportToImage = () => {
    const canvas = document.getElementById("rcmChart");
    canvas.toBlob((blob) => {
      saveAs(blob, "RCM_Indicators.png");
    });
  };

  return (
    <div className="indicators container mt-5">
      <div id="date_buttons" className="mb-4">
         {/* قسم اختيار القسم مع العنوان */}
         <label htmlFor="departmentSelect" className="mr-2">Indicator:</label>
        <select
          id="departmentSelect"
          className="form-control custom-select mb-2"
          value={selectedDepartment}
          onChange={(e) => setSelectedDepartment(e.target.value)}
        >
          <option value="Inpatients">Inpatients</option>
          <option value="Outpatients">Outpatients</option>
          <option value="ER">ER</option>
        </select>

        {/* خط فاصل */}
        <hr />

        <span className="d-flex align-items-center">
  {/* أزرار التاريخ */}
  <span className="date_buttons_span">
    {['today', 'yesterday', 'this_week', 'last_week', 'this_month', 'this_year', 'custom'].map((type) => (
      <button
        key={type}
        type="button"
        className={`date_button ${type === 'today' ? 'active_btn' : ''} btn primary mx-1`}
        data-type={type}
        onClick={() => handleDateButtonClick(type)}
      >
        {type.charAt(0).toUpperCase() + type.slice(1).replace(/_/g, ' ')}
      </button>
    ))}
  </span>

  {/* Dropdown for Export Options */}
  <div className="export-options d-flex justify-content-start ml-3">
    <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
      <DropdownToggle color="primary">
        Export
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={exportToExcel}>Export to Excel</DropdownItem>
        <DropdownItem onClick={exportToPDF}>Export to PDF</DropdownItem>
        <DropdownItem onClick={exportToImage}>Export to Image</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>
</span>
      </div>

      {/* خط فاصل */}
      <hr />

      {/* إضافة مربع المؤشرات هنا */}
      <div id="statistic-boxes-area">
        <div className="row m-0">
        <div className="col-xl-3 col-lg-6 p-0 pr-lg-3 mb-4 mb-lg-0">
            <div className="statistic-boxes">
              <div className="box-icon-div">
                <div className="appointment-status-icon" style={{ background: '#fd9b782b' }} >
                    <FaClock style={{ color: '#fd9b78' }} />
                </div>
              </div>
              <div className="box-number-div" data-stat="pended">0</div>
              <div className="box-title-div">Pended</div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 p-0 pr-lg-3 mb-4 mb-lg-0">
            <div className="statistic-boxes">
              <div className="box-icon-div">
                <div className="appointment-status-icon" style={{ background: '#e9f9fb' }} >
                    <FaCheck style={{ color: '#26c0d6' }} />
                </div>
              </div>
              <div className="box-number-div" data-stat="approved">0</div>
              <div className="box-title-div">Approved</div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 p-0 pr-lg-3 mb-4 mb-lg-0">
            <div className="statistic-boxes">
              <div className="box-icon-div">
                <div className="appointment-status-icon" style={{ background: '#8f9ca72b' }} >
                    <FaBan style={{ color: '#8f9ca7' }} />
                </div>
              </div>
              <div className="box-number-div" data-stat="rejected">0</div>
              <div className="box-title-div">Rejected</div>
            </div>
          </div>
          
          <div className="col-xl-3 col-lg-6 p-0 pr-lg-3 mb-4 mb-lg-0">
            <div className="statistic-boxes">
              <div className="box-icon-div">
              <div className="appointment-status-icon" style={{ background: '#53d56c2b' }} >
              <FaWallet style={{ color: '#53d56c' }} />
              </div>
              </div>
              <div className="box-number-div" data-stat="revenue">0.00 SR</div>
              <div className="box-title-div">Revenue</div>
            </div>
          </div>
        </div>
      </div>

      {/* خط فاصل */}
      <hr />

      <div className="chart-section mb-4">
        <Bar id="rcmChart" data={data} />
      </div>

      

      
    </div>
  );
};

export default Indicators;