import React, { useState } from "react";
import axios from "axios";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { Button, TabContent, TabPane, Nav, NavItem, NavLink, InputGroup, Input } from 'reactstrap';  // تأكد من استيراد المكتبات المطلوبة
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { FaPlus } from 'react-icons/fa';  // استخدم أيقونة الزائد

export const InsuranceValidatorComponent = () => {
  const [file, setFile] = useState(null);
  const [identifiers, setIdentifiers] = useState([]);
  const [currentIdentifier, setCurrentIdentifier] = useState("");
  const [operations, setOperations] = useState([]);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("Idle");
  const [analysisComplete, setAnalysisComplete] = useState(false);
  const [activeTab, setActiveTab] = useState('1');

  // Handle file change with dropzone
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const allowedTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv"
    ];

    if (file && allowedTypes.includes(file.type)) {
      setFile(file);
    } else {
      alert("Please upload a valid Excel or CSV file.");
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: [
      ".xls", 
      ".xlsx", 
      ".csv", 
      "application/vnd.ms-excel", 
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv"
    ]
  });

  // Toggle tabs
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  };

  // Handle file submit
  const handleFileSubmit = async () => {
    if (!file) {
      alert("Please upload a file!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      setStatus("Processing...");
      const response = await axios.post("https://your-api-endpoint/upload", formData, {
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentage);
        },
      });

      if (response.status === 200) {
        const { operationId, recordCount, insuredCount } = response.data;
        setOperations((prev) => [
          ...prev,
          { operationId, recordCount, insuredCount },
        ]);
        setStatus("Completed");
        setAnalysisComplete(true);
      }
    } catch (error) {
      console.error("Error uploading file", error);
      setStatus("Failed");
    }
  };

  // Handle adding identifier
  const handleAddIdentifier = () => {
    if (!currentIdentifier.trim()) return;
    setIdentifiers([...identifiers, currentIdentifier]);
    setCurrentIdentifier("");
  };

  // Handle removing identifier
  const handleRemoveIdentifier = (index) => {
    const updatedIdentifiers = identifiers.filter((_, i) => i !== index);
    setIdentifiers(updatedIdentifiers);
  };

  // Handle identifier submit
  const handleIdentifierSubmit = async () => {
    if (identifiers.length === 0) {
      alert("Please add some identifiers!");
      return;
    }

    try {
      setStatus("Processing...");
      const response = await axios.post("https://your-api-endpoint/identifiers", { identifiers });

      if (response.status === 200) {
        const { operationId, recordCount, insuredCount } = response.data;
        setOperations((prev) => [
          ...prev,
          { operationId, recordCount, insuredCount },
        ]);
        setStatus("Completed");
        setAnalysisComplete(true);
      }
    } catch (error) {
      console.error("Error sending identifiers", error);
      setStatus("Failed");
    }
  };

  // Navigate to results page
  const handleViewResults = () => {
    window.location.href = `/results/${operations[operations.length - 1].operationId}`;
  };

  return (
    <>
      <div className="mb-5">
        <h1>Insurance Validator</h1>
        <p className="lead">Verifies the accuracy of patient insurance data.</p>

        {/* Tabs for selecting query methods */}
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => { toggle('1'); }}
            >
              Upload Excel/CSV File
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => { toggle('2'); }}
            >
              Enter Identifiers
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="data-upload-page mt-3">
              <h3>Upload Excel/CSV File</h3>
              <p>Upload CSV/Excel file with the following columns:</p>
              <ul>
                <li>Column 1 (MUST): ID</li>
                <li>Column 2: MRN</li>
                <li>Column 3: Name</li>
                <li>Column 4: Department</li>
                <li>Column 5: Clinic</li>
              </ul>
              <div
                {...getRootProps({ className: 'dropzone border border-primary p-5 text-center' })}
              >
                <input {...getInputProps()} />
                {
                  isDragActive ?
                    <p>Drop the file here...</p> :
                    <p>Drag 'n' drop a valid Excel/CSV file here, or click to select file</p>
                }
              </div>
              {file && <p>File: {file.name}</p>}
              <Button color="primary" onClick={handleFileSubmit} className="mt-3">Submit File</Button>
              {progress > 0 && <p>Upload Progress: {progress}%</p>}
            </div>
          </TabPane>

          <TabPane tabId="2">
            <div className="mt-3">
              <h3>Enter Identifiers</h3>
              <InputGroup className="mb-3">
                <Input
                  type="text"
                  value={currentIdentifier}
                  onChange={(e) => setCurrentIdentifier(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleAddIdentifier()}
                  placeholder="Enter Identifier"
                />
                <Button color="primary" onClick={handleAddIdentifier}>
                  <FaPlus />
                </Button>
              </InputGroup>

              {identifiers.length > 0 && (
                <div className="mt-4">
                  <h4>Identifiers List</h4>
                  <ul className="list-group">
                    {identifiers.map((id, index) => (
                      <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                        {id}
                        <button className="btn btn-danger btn-sm" onClick={() => handleRemoveIdentifier(index)}>
                          &times;
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <Button color="primary" onClick={handleIdentifierSubmit} className="mt-3">Submit Identifiers</Button>
            </div>
          </TabPane>
        </TabContent>

        <div className="mt-5">
          <h2>Operations Progress</h2>
          {operations.length > 0 ? (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Operation ID</th>
                  <th>Record Count</th>
                  <th>Insured Count</th>
                </tr>
              </thead>
              <tbody>
                {operations.map((op, index) => (
                  <tr key={index}>
                    <td>{op.operationId}</td>
                    <td>{op.recordCount}</td>
                    <td>{op.insuredCount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No operations yet.</p>
          )}
        </div>

        {analysisComplete && (
          <Button color="info" onClick={handleViewResults} className="mt-3">View Results</Button>
        )}
      </div>
    </>
  );
};

// Protect this route with Auth0 authentication
export default withAuthenticationRequired(InsuranceValidatorComponent, {
  onRedirecting: () => <Loading />,
});